

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();
  console.log("entro a borrar los tokens");
  useEffect(() => {
    // Borrar los tokens de autenticación del almacenamiento local
    console.log("entro a borrar token");
    localStorage.removeItem('token');
    console.log("entro a borrar refreshToken");
    localStorage.removeItem('refreshToken');
    console.log("reenviando...  ");
    // Redirigir al usuario al login
    navigate('/login');
  }, [navigate]);

  return (
    <div>
      <h1>Logged out</h1>
      <p>You have been logged out successfully.</p>
    </div>
  );
};

export default LogoutPage;






// import React, { useEffect, useContext, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import styled from 'styled-components';
// import { AuthContext } from '../context/AuthContext';
// import logo from '../assets/logo.png';

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
//   background-color: ${(props) => props.theme.colors.accent};
// `;

// const Logo = styled.img`
//   width: 150px;
//   height: auto;
//   margin-bottom: 20px;
// `;

// const Title = styled.h1`
//   margin-bottom: 1rem;
// `;

// const Text = styled.p`
//   margin-bottom: 2rem;
//   font-size: 1.2rem;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   gap: 1rem;
// `;

// const Button = styled.button`
//   padding: 0.5rem 1rem;
//   font-size: 1rem;
//   background-color: ${(props) => props.theme.colors.primary};
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   &:hover {
//     background-color: ${(props) => props.theme.colors.highlight};
//   }
// `;

// const LogoutPage = () => {
//   const { logout } = useContext(AuthContext);
//   const navigate = useNavigate();
//   const [isLoggingOut, setIsLoggingOut] = useState(false);

//   const handleLogout = async () => {
//     setIsLoggingOut(true);
    
//     // Eliminar los tokens almacenados
//     localStorage.removeItem('token');
//     localStorage.removeItem('refreshToken');

//     // Llamar al método logout del contexto para limpiar el estado global
//     logout();
    
//     // Redirigir al usuario a la página de login
//     navigate('/login');
//   };

//   useEffect(() => {
//     console.log("entro a la validacion si esta logeado");
//     console.log(localStorage.getItem('token'));
//     console.log("isLoggingOut:", isLoggingOut);
//     // Si el usuario ya está deslogueado, redirigir al login
//     if (!localStorage.getItem('token') && !isLoggingOut) {
//       console.log(localStorage.getItem('token'));
//       console.log("entro a la validacion de reenvinar al login");
//       navigate('/login');

//     }
//   }, [navigate, isLoggingOut]);

//   return (
//     <Container>
//       <Logo src={logo} alt="Pilou Logo" />
//       <Title>Salida <span role="img" aria-label="door">🚪</span></Title>
//       <Text>¿Quieres salir?</Text>
//       <ButtonContainer>
//         <Button onClick={handleLogout} disabled={isLoggingOut}>
//           Si, quiero salir
//         </Button>
//         <Button onClick={() => navigate('/dashboard')} disabled={isLoggingOut}>
//           No, Regresame a donde estaba
//         </Button>
//       </ButtonContainer>
//     </Container>
//   );
// };

// export default LogoutPage;
