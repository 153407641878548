// src/styles/Theme.js
const themes = {
  female: {
    colors: {
      primary: '#FF5699',
      highlight: '#FFBBCC',
      background: '#F0FCFC',
      sidebar: '#FFFE9F',
      notification: '#C0C0C0',
      notificationActive: '#FF0000',
    },
    fonts: {
      main: 'Raleway, sans-serif',
      secondary: 'Aeonic, sans-serif',
    },
  },
  male: {
    colors: {
      primary: '#1E90FF',
      highlight: '#ADD8E6',
      background: '#F0FCFC',
      sidebar: '#B0E0E6',
      notification: '#C0C0C0',
      notificationActive: '#FF0000',
    },
    fonts: {
      main: 'Raleway, sans-serif',
      secondary: 'Aeonic, sans-serif',
    },
  },
  nonBinary: {
    colors: {
      primary: '#32CD32',
      highlight: '#98FB98',
      background: '#F0FCFC',
      sidebar: '#9ACD32',
      notification: '#C0C0C0',
      notificationActive: '#FF0000',
    },
    fonts: {
      main: 'Raleway, sans-serif',
      secondary: 'Aeonic, sans-serif',
    },
  },
  preferNotToSay: {
    colors: {
      primary: '#FFA500',
      highlight: '#FFD700',
      background: '#F0FCFC',
      sidebar: '#FFEFD5',
      notification: '#C0C0C0',
      notificationActive: '#FF0000',
    },
    fonts: {
      main: 'Raleway, sans-serif',
      secondary: 'Aeonic, sans-serif',
    },
  },
};

export { themes };
