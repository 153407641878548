import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import { themes } from './styles/Theme';
import LoginPage from './pages/LoginPage';
import Layout from './components/Layout';
import { AuthProvider } from './context/AuthContext';
import Profile from './pages/Profile';
import LogoutPage from './pages/LogoutPage';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard';
import AuditPage from './pages/AuditPage';
import SettingsPage from './pages/SettingsPage';
import UserManagement from './pages/UserManagement';

function App() {
  return (
    <ThemeProvider theme={themes.female}>
      <GlobalStyles />
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/profile" element={<Profile />} />
              <Route path="/dashboard/audit" element={<AuditPage />} />
              <Route path="/dashboard/settings" element={<SettingsPage />} />
              <Route path="/dashboard/settings/user-management" element={<UserManagement />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
