// src/components/UserProfile.js
import React from 'react';
import styled from 'styled-components';

const UserProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
`;

const UserImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const UserName = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 5px;
`;

const UserProfile = ({ user }) => {
  return (
    <UserProfileContainer>
      <UserImage src={user.image} alt="User Profile" />
      <UserName>{user.name}</UserName>
    </UserProfileContainer>
  );
};

export default UserProfile;
