// src/pages/Profile.js
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext.js';


const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
`;

const Select = styled.select`
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
`;

const Label = styled.label`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem;
  font-size: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.highlight};
  }
`;

const Profile = () => {
  const { user, setUser, refreshToken } = useContext(AuthContext);
  const [name, setName] = useState(user?.first_name || '');
  const [surname, setSurname] = useState(user?.last_name || '');
  const [homePhone, setHomePhone] = useState(user?.phone_home || '');
  const [cellPhone, setCellPhone] = useState(user?.phone_mobile || '');
  const [email, setEmail] = useState(user?.personal_email || '');
  const [birthdate, setBirthdate] = useState(user?.birth_date || '');
  const [gender, setGender] = useState(user?.gender || '');
  const [twitter, setTwitter] = useState(user?.twitter || '');
  const [linkedin, setLinkedin] = useState(user?.linkedin || '');
  const [age, setAge] = useState('');
  const [daysToBirthday, setDaysToBirthday] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState(user?.employee_number || '');

  useEffect(() => {
    if (birthdate) {
      const birthDate = new Date(birthdate);
      const ageDiffMs = Date.now() - birthDate.getTime();
      const ageDate = new Date(ageDiffMs);
      setAge(Math.abs(ageDate.getUTCFullYear() - 1970));

      const nextBirthday = new Date(birthDate.setFullYear(new Date().getFullYear()));
      if (nextBirthday < new Date()) {
        nextBirthday.setFullYear(new Date().getFullYear() + 1);
      }
      const days = Math.ceil((nextBirthday - new Date()) / (1000 * 60 * 60 * 24));
      setDaysToBirthday(days);
    }
  }, [birthdate]);

  const handleSave = async (e) => {
    e.preventDefault();
    // Validar campos obligatorios
    if (!name || !surname || !homePhone || !cellPhone || !email) {
      alert('Por favor, complete todos los campos obligatorios');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await fetch(`https://proto-pilou.pilou.io/api/users/${user.id}/`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: name,
          last_name: surname,
          phone_home: homePhone,
          phone_mobile: cellPhone,
          personal_email: email,
          birth_date: birthdate,
          gender,
          twitter,
          linkedin,
        }),
      });
      setUser((prev) => ({ ...prev, first_name: name, last_name: surname, phone_home: homePhone, phone_mobile: cellPhone, personal_email: email, birth_date: birthdate, gender, twitter, linkedin }));
      alert('Perfil actualizado exitosamente');
    } catch (error) {
      console.error('Error actualizando el perfil', error);
      alert('Hubo un error actualizando el perfil');
    }
  };

  return (
    <ProfileContainer>
      <Form onSubmit={handleSave}>
        <Label>Subir Imagen de Perfil</Label>
        <Input type="file" accept="image/*" />
        <Input 
          type="text" 
          placeholder="Nombre" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          required 
        />
        <Input 
          type="text" 
          placeholder="Apellidos" 
          value={surname} 
          onChange={(e) => setSurname(e.target.value)} 
          required 
        />
        <Input 
          type="tel" 
          placeholder="Teléfono de Casa" 
          value={homePhone} 
          onChange={(e) => setHomePhone(e.target.value)} 
          required 
        />
        <Input 
          type="tel" 
          placeholder="Teléfono de Celular" 
          value={cellPhone} 
          onChange={(e) => setCellPhone(e.target.value)} 
          required 
        />
        <Input 
          type="email" 
          placeholder="Correo Electrónico Personal" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <Input 
          type="date" 
          placeholder="Fecha de Nacimiento" 
          value={birthdate} 
          onChange={(e) => setBirthdate(e.target.value)} 
        />
        <Select 
          value={gender} 
          onChange={(e) => setGender(e.target.value)}
        >
          <option value="">Seleccione su género</option>
          <option value="F">Femenino</option>
          <option value="M">Masculino</option>
          <option value="N">No Binario</option>
          <option value="P">Prefiero no decir</option>
        </Select>
        <Input 
          type="text" 
          placeholder="Twitter" 
          value={twitter} 
          onChange={(e) => setTwitter(e.target.value)} 
        />
        <Input 
          type="text" 
          placeholder="LinkedIn" 
          value={linkedin} 
          onChange={(e) => setLinkedin(e.target.value)} 
        />
        <Label>Edad: {age ? `Tienes ${age} años y faltan ${daysToBirthday} días para tu próximo cumpleaños` : ''}</Label>
        <Label>Tu número de colaborador es {employeeNumber ? employeeNumber : ':( aún no tenemos tu número de empleado'}</Label>
        <Button type="submit">Guardar</Button>
        <Button type="button" onClick={() => window.history.back()}>Volver</Button>
      </Form>
    </ProfileContainer>
  );
};

export default Profile;
