// src/components/SidebarMenu.js
import React from 'react';
import styled from 'styled-components';
import { FaHome, FaUser, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MenuContainer = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.background};
`;

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    margin-right: 10px;
  }
`;

const SidebarMenu = ({ onLogout }) => {
  return (
    <MenuContainer>
      <MenuItem to="/dashboard">
        <FaHome /> Home
      </MenuItem>
      <MenuItem to="/profile">
        <FaUser /> Profile
      </MenuItem>
      <MenuItem to="/settings">
        <FaCog /> Settings
      </MenuItem>
      <MenuItem as="div" onClick={onLogout}>
        <FaSignOutAlt /> Logout
      </MenuItem>
    </MenuContainer>
  );
};

export default SidebarMenu;
