// src/pages/SettingsPage.js
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.background};
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.primary};
`;

const SettingsMenu = styled.nav`
  margin-bottom: 20px;
`;

const MenuItem = styled(Link)`
  padding: 10px;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  margin-right: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const SettingsPage = () => {
  return (
    <Container>
      <Title>Configuración</Title>
      <SettingsMenu>
        <MenuItem to="/dashboard/settings/user-management">Administración de Usuarios</MenuItem>
      </SettingsMenu>
      <Outlet />
    </Container>
  );
};

export default SettingsPage;
