// src/components/Sidebar.js
import React from 'react';
import styled from 'styled-components';
import UserProfile from './UserProfile';
import SidebarMenu from './SidebarMenu';

const SidebarContainer = styled.div`
  width: 250px;
  background-color: ${({ theme }) => theme.colors.background};
  position: fixed;
  top: 50px;  /* Ajusta según el TopBar */
  bottom: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
  transition: left 0.3s ease;
  z-index: 999;

  @media (max-width: 768px) {
    width: 200px;
  }
`;

const Sidebar = ({ isOpen, user, onLogout }) => {
  console.log('Sidebar', user);
  return (
    <SidebarContainer isOpen={isOpen}>
      <UserProfile user={user} />
      //<SidebarMenu onLogout={onLogout} />
    </SidebarContainer>
  );
};

export default Sidebar;
