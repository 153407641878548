// src/pages/UserManagement.js
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.background};
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.primary};
`;

const UserManagement = () => {
  return (
    <Container>
      <Title>Administración de Usuarios</Title>
      {/* Aquí puedes añadir la lógica y componentes necesarios para la administración de usuarios */}
    </Container>
  );
};

export default UserManagement;
