import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext.js';
import styled from 'styled-components';
import LoadingSpinner from '../components/LoadingSpinner.js';
import logo from '../assets/logo.png';  // Asegúrate de importar la ruta correcta del logo

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.accent};
`;

const Logo = styled.img`
  width: 150px; /* Ajusta el tamaño según sea necesario */
  height: auto;
  margin-bottom: 20px;
  loading: 'lazy';
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem;
  font-size: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.highlight};
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.primary};
`;

const ForgotPasswordLink = styled.a`
  margin-top: 1rem;
  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.highlight};
  }
`;

const LoginPage = () => {
  const { login, authenticating, user } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [loadTime, setLoadTime] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
    const timing = window.performance.timing;
    const loadTime = timing.loadEventEnd - timing.responseStart;
    setLoadTime(loadTime);
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!email || !password) {
      setError('Both fields are required');
      return;
    }

    try {
      await login(email, password);
    } catch (error) {
      setError('Login failed: ' + error.message);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError('');

    if (!email) {
      setError('Email is required');
      return;
    }

    try {
      await fetch('https://proto-pilou.pilou.io/api/auth/password/reset/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      setError('Password reset link sent');
    } catch (error) {
      setError('Password reset failed');
    }
  };

  return (
    <LoginContainer>
      {authenticating ? (
        <LoadingSpinner />
      ) : (
        <>
          <Logo src={logo} alt="Pilou Logo" />
          {!isForgotPassword ? (
            <LoginForm onSubmit={handleSubmit}>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <Input 
                type="email" 
                placeholder="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
              <Input 
                type="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                autocomplete="on"
                required 
              />
              <Button type="submit">Login</Button>
              <ForgotPasswordLink onClick={() => setIsForgotPassword(true)}>
                Forgot Password?
              </ForgotPasswordLink>
            </LoginForm>
          ) : (
            <LoginForm onSubmit={handleForgotPassword}>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <Input 
                type="email" 
                placeholder="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
              <Button type="submit">Send Reset Link</Button>
              <Button type="button" onClick={() => setIsForgotPassword(false)}>
                Cancel
              </Button>
            </LoginForm>
          )}
          <Footer>
            &copy; {new Date().getFullYear()} Pilou. All rights reserved. <br />
            <a href="/terms">Terms and Conditions</a> | <a href="/privacy">Privacy Policy</a> <br />
            Page loaded in {loadTime} ms.
          </Footer>
        </>
      )}
    </LoginContainer>
  );
};

export default LoginPage;
