import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { themes } from '../styles/Theme';
import api from '../api/api';
import jwt_decode from 'jwt-decode';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authenticating, setAuthenticating] = useState(false);
  const [theme, setTheme] = useState(themes.female); // Default theme

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getUserData(token);
    } else {
      setLoading(false);
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    if (user) {
      switch (user.gender) {
        case 'M':
          setTheme(themes.male);
          break;
        case 'F':
          setTheme(themes.female);
          break;
        case 'N':
          setTheme(themes.nonBinary);
          break;
        case 'P':
          setTheme(themes.preferNotToSay);
          break;
        default:
          setTheme(themes.preferNotToSay);
      }
    }
  }, [user]);


  const login = async (email, password) => {
    console.log("Login process started...");
    setAuthenticating(true);

    try {
        console.log("Sending login request with email:", email);
        const response = await api.post('/token/', { username: email, password });

        console.log("Login request sent. Awaiting response...");

        // Omitimos la validación de response.ok y accedemos directamente a los datos
        const data = await response.data; // Accede directamente a response.data
        // console.log("Response received. Parsed data:", data);

        if (data.access && data.refresh) {
            // console.log("Tokens received. Access token:", data.access);
            localStorage.setItem('token', data.access);
            localStorage.setItem('refreshToken', data.refresh);
            // console.log("Tokens saved to localStorage.");

            console.log("Fetching user data with the access token...");
            getUserData(data.access);
        } else {
            console.error("Tokens were not found in the response.");
        }
    } catch (error) {
        console.error("An error occurred during the login process:", error);
        throw error;
    } finally {
        setAuthenticating(false);
        console.log("Login process finished.");
    }
};



  const refreshToken = async () => {
    const refresh = localStorage.getItem('refreshToken');
    console.log("Starting refreshToken process...");

    if (refresh) {
        console.log("Refresh token found:", refresh);

        try {
            const response = await api.post('/token/refresh/', { refresh });
            // console.log("Refresh token request sent. Awaiting response...");

            if (!response.ok) { 
                console.error("Failed to refresh token. Status code:", response.status);
                logout();
                return;
            }

            console.log("Response received:", response);

            const data = await response.json();
            console.log("Parsed JSON data:", data);

            if (data && data.access) {
                localStorage.setItem('token', data.access);
                console.log("New access token saved:", data.access);
            } else {
                console.error("No access token found in response. Logging out...");
                logout();
            }
        } catch (error) {
            console.error("An error occurred while refreshing token. Logging out...", error);
            logout();
        }
    } else {
        console.log("No refresh token available. Logging out...");
        logout();
    }
};



  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  const getUserData = async (token) => {
    try {
        console.log("Invocando /users/me/");
        const response = await api.get('/users/me/', {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        // Axios ya parsea el JSON automáticamente, así que no necesitas response.json()
        const data = response.data;

        // Seteando valores por defecto en caso de que algunos campos no vengan
        const userData = {
            id: data.id,
            username: data.username || 'No User Name',
            first_name: data.first_name || '',
            last_name: data.last_name || '',
            phone_home: data.phone_home || '',
            phone_mobile: data.phone_mobile || '',
            personal_email: data.personal_email || '',
            birth_date: data.birth_date || null,
            gender: data.gender || 'P', // 'P' para PreferNotToSay
            twitter: data.twitter || '',
            linkedin: data.linkedin || ''
        };

        setUser(userData);
        console.log("Confirmando que userData tenga datos ",userData);
        
        console.log("Confirmando que User ternga datos:", user);
        
        console.log("Reenviando a dashboard");
        navigate('/dashboard');
    } catch (error) {
        console.log("Ocurrió un error");

        // Verifica si el error tiene una respuesta del servidor
        if (error.response) {
            console.error("Error status:", error.response.status);
            console.error("Error data:", error.response.data);
        } else if (error.request) {
            // Si el error ocurrió durante la solicitud, pero no hubo respuesta
            console.error("No hubo respuesta del servidor:", error.request);
        } else {
            // Otros tipos de errores, como errores de configuración
            console.error("Error en la configuración:", error.message);
        }

        logout();  // Llamar al logout en caso de error
    } finally {
        setLoading(false);
    }
};

useEffect(() => {
  if (user) {
      console.log("User actualizado:", user);
  }
}, [user]);


  return (
    <AuthContext.Provider value={{ user, loading, authenticating, login, logout, refreshToken, setUser }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </AuthContext.Provider>
  );
};


export { AuthContext, AuthProvider };

