import axios from 'axios';
import jwt_decode from 'jwt-decode';

const api = axios.create({
    baseURL: 'https://proto-pilou.pilou.io/api', // Cambia la URL por la de tu API
});

// Interceptor para agregar el token en cada solicitud
api.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Interceptor para manejar errores de autenticación y refrescar el token
api.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refreshToken');
        
        if (refreshToken) {
            try {
                const response = await api.post('/token/refresh/', {
                    refresh: refreshToken,
                });

                const { access } = response.data;
                localStorage.setItem('accessToken', access);
                
                originalRequest.headers.Authorization = `Bearer ${access}`;

                return api(originalRequest);
            } catch (err) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                // Redirigir al login si el refresco falla
                window.location.href = '/login';
            }
        }
    }

    return Promise.reject(error);
});

export default api;
